import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import titreRubrique from "../assets/images/accueil/puce_titre_rubrique.gif"
import mainTitle from "../assets/images/titres/acces_EC3D.gif"
import contacts from "../assets/images/titres/nos_contacts_EC3D.gif"
import 'react-slidedown/lib/slidedown.css'
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import "./contact-narcastet.css"

const contactNarcastetPage = ({data}) => (
  <Layout>
    <SEO title="Contact et accès - Narcastet - EC3D" />
    <div id="contact" class="rubrique">
      <div id="titre">
          <img src={titreRubrique} width="25" height="32" alt="puce titre rubrique"/>
          <span class="h1"><img src={mainTitle} width="518" height="32" alt="Rubrique Notre équipement - EC3D" /></span>
          <p class="titre-texte h2">
          </p>
      </div>
              
      <div id="contenu">

        <div class="block main-block">
          <div class="inner-block">
            <div class="h3" dangerouslySetInnerHTML={{ __html: documentToHtmlString(data.allContentfulContact.edges[0].node.introduction.json) }}>
            </div>
            <div class="google-map">
              <iframe title="narcastet-map" width="602" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1135.224775822935!2d-0.31003173254709965!3d43.24317508627106!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7c9d7478714b659d!2sExpertise%20Controle%203D!5e1!3m2!1sfr!2sfr!4v1581080573962!5m2!1sfr!2sfr"></iframe>
            </div>
          </div>
        </div>

        <div class="block side-block">
          <div class="inner-block">
            <div class="block-title h1"><img src={contacts} width="171" height="22" alt="Innovation EC3D" />
            </div>
            <div class="h3">
              <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(data.allContentfulContact.edges[0].node.contacts.json) }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default contactNarcastetPage

export const query = graphql`
    query {
      allContentfulContact(filter: {title: {regex: "/Narcastet/"}}) {
        edges {
          node {
            id,
            introduction {
              json
            },
            contacts{
              json
            }
          }
        }
      }
    }
  `